// import React, { useEffect, useState } from 'react';
// import { db, auth } from '../firebase';
// import { collection, getDocs } from 'firebase/firestore';
// import axios from 'axios';
// import { useLocation } from 'react-router-dom';
// import '../style/OrdersPage.css';
// import Sidebar from '../components/Sidebar';
// import Header from '../components/Header';

// const InTransit = () => {
//     const [rtoOrders, setRtoOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const location = useLocation();
//     const [searchTerm, setSearchTerm] = useState('');
//     const [filteredOrders, setFilteredOrders] = useState([]);
//     const queryParams = new URLSearchParams(location.search);
//     const shopId = queryParams.get('shop');

//     const user = auth.currentUser;
//     if (!user) {
//         throw new Error("User not logged in");
//     }

//     const fetchRtoOrders = async () => {
//         try {
//             const ordersCollection = collection(db, 'users', user.uid, 'orders');
//             const ordersSnapshot = await getDocs(ordersCollection);
//             const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            
//             const filteredOrders = [];
//             for (const order of ordersList) {
//                 const trackingInfo = await fetchTrackingData(order.id);
//                 if ((trackingInfo.current_status === "In Transit" || 
//                     trackingInfo.current_status === "Pickup Rescheduled" ||
//                     trackingInfo.current_status === "Out For Delivery" || 
//                     trackingInfo.current_status === "Out For Pickup" ||
//                     trackingInfo.current_status === "Pickup Exception" ||
//                     trackingInfo.current_status === "Pickup Exception" ||
//                     trackingInfo.current_status.includes("Transit") ||
//                     trackingInfo.current_status.includes("transit") ||
//                     trackingInfo.current_status.includes("TRANSIT") ||
//                     trackingInfo.current_status.includes("Transit-En-Route"))
//                     &&
//                     !trackingInfo.current_status.includes("RTO") // Exclude RTO          
//                 ) {
//                     filteredOrders.push({ ...order, tracking_status: trackingInfo.current_status });
//                 }
//             }
            
//             setRtoOrders(filteredOrders);
//         } catch (error) {
//             console.error("Error fetching RTO orders:", error);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const fetchTrackingData = async (orderId) => {
//         try {
//             const response = await axios.get(`https://ufb-1.onrender.com/api/track-order?order_id=${orderId}`);
//             if (response.data && response.data.current_status) {
//                 return {
//                     tracking_url: response.data.tracking_url,
//                     current_status: response.data.current_status
//                 };
//             }
//         } catch (error) {
//             console.error("Error fetching tracking data for order", orderId, error);
//         }
//         return { tracking_url: null, current_status: "Status not available" };
//     };

//     useEffect(() => {
//         fetchRtoOrders();
//     }, [shopId]);


//         useEffect(() => {
//             const filtered = rtoOrders.filter(order => 
//                 order.id.includes(searchTerm) || 
//                 (order.Storeorder_id && order.Storeorder_id.includes(searchTerm))
//             );
//             setFilteredOrders(filtered);
//         }, [searchTerm, rtoOrders]);

//     if (loading) {
//         return <div>Loading InTransit orders</div>;
//     }

//     return (
//         <div className="orders-page">
//             <Header />
//             <div className="orders-container">
//                 <Sidebar />
//                 <div className="main-content">
//                     <h1>In Transit</h1>
//                     <input 
//                         type="text" 
//                         placeholder="Search by Order ID or Store Order ID" 
//                         value={searchTerm} 
//                         onChange={(e) => setSearchTerm(e.target.value)} 
//                         className="search-bar"
//                     />
//                     <table className="orders-table">
//                         <thead>
//                             <tr>
//                                 <th>Order ID</th>
//                                 <th>Unitee Order ID</th>
//                                 <th>SKU</th>
//                                 <th>Quantity</th>
//                                 <th>Current Status</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                         {filteredOrders
//                                 .map(order => (
//                                 <tr key={order.id}>
//                                     <td>{order.Storeorder_id || 'NA'}</td>
//                                     <td>{order.id || 'NA'}</td>
//                                     <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.sku}</p>
//                                         ))}
//                                     </td>
//                                     <td>
//                                         {order.order_items.map((item, index) => (
//                                             <p key={index}>{item.units}</p>
//                                         ))}
//                                     </td>
//                                     <td>{order.tracking_status}</td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default InTransit;





// import React, { useEffect, useState } from 'react';
// import { db, auth } from '../firebase';
// import { collection, getDocs, query, where, limit, startAfter, orderBy } from 'firebase/firestore';
// import { useLocation } from 'react-router-dom';
// import '../style/OrdersPage.css';
// import Sidebar from '../components/Sidebar';
// import Header from '../components/Header';

// const InTransit = () => {
//     const [inTransitOrders, setInTransitOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [searchTerm, setSearchTerm] = useState('');
//     const [filteredOrders, setFilteredOrders] = useState([]);
//     const [lastVisible, setLastVisible] = useState(null);
//     const [loadingMore, setLoadingMore] = useState(false);
//     const [noMoreOrders, setNoMoreOrders] = useState(false);
//     const [error, setError] = useState(null);
//     const location = useLocation();
//     const queryParams = new URLSearchParams(location.search);
//     const shopId = queryParams.get('shop');
//     const ordersPerPage = 10;

//     const user = auth.currentUser;
//     if (!user) {
//         throw new Error("User not logged in");
//     }

//     // These are the statuses we want to display
//     const statusValues = [
//         'PICKED UP',
//         'Pickup Exception',
//         'REACHED AT DESTINATION HUB',
//         'SHIPPED',
//         'IN TRANSIT',
//         'In Transit-AT DESTINATION HUB',
//         'In Transit-EN-ROUTE',
//         'OUT FOR DELIVERY',
//         'DELIVERY DELAYED'
//     ];

//     const fetchInTransitOrders = async (isInitialLoad = true) => {
//         try {
//             if (isInitialLoad) {
//                 setLoading(true);
//                 setInTransitOrders([]);
//                 setError(null);
//             } else {
//                 setLoadingMore(true);
//             }

//             const ordersCollection = collection(db, 'users', user.uid, 'orders');
            
//             // DEBUGGING: First try to get all orders to verify data exists
//             const debugQuery = query(ordersCollection);
//             const debugSnapshot = await getDocs(debugQuery);
//             console.log(`DEBUG: Found ${debugSnapshot.size} total orders`);
            
//             // Log how many orders have tracking_status in our array
//             const validOrders = debugSnapshot.docs.filter(doc => 
//                 statusValues.includes(doc.data().tracking_status)
//             );
//             console.log(`DEBUG: Found ${validOrders.length} orders with matching tracking_status`);
            
//             // Try without the timestamp ordering first to see if that's the issue
//             let q = query(
//                 ordersCollection,
//                 where('tracking_status', 'in', statusValues),
//                 limit(ordersPerPage)
//             );

//             // If we're loading more, use the lastVisible document as the starting point
//             if (!isInitialLoad && lastVisible) {
//                 q = query(
//                     ordersCollection,
//                     where('tracking_status', 'in', statusValues),
//                     startAfter(lastVisible),
//                     limit(ordersPerPage)
//                 );
//             }

//             const ordersSnapshot = await getDocs(q);
//             console.log(`DEBUG: Query returned ${ordersSnapshot.size} orders`);
            
//             // If no documents returned and not initial load, we've reached the end
//             if (ordersSnapshot.empty && !isInitialLoad) {
//                 setNoMoreOrders(true);
//                 setLoadingMore(false);
//                 return;
//             }

//             if (ordersSnapshot.empty && isInitialLoad) {
//                 console.log("No orders found with the specified tracking statuses");
//                 setError("No orders found with the specified tracking statuses");
//                 setInTransitOrders([]);
//                 return;
//             }

//             const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//             console.log("Fetched orders:", ordersList);
            
//             // Update lastVisible for pagination
//             const lastDoc = ordersSnapshot.docs[ordersSnapshot.docs.length - 1];
//             setLastVisible(lastDoc);

//             // Append or set orders based on initial load or loading more
//             setInTransitOrders(prevOrders => (
//                 isInitialLoad ? ordersList : [...prevOrders, ...ordersList]
//             ));

//         } catch (error) {
//             console.error("Error fetching in-transit orders:", error);
//             setError(`Error fetching orders: ${error.message}`);
//         } finally {
//             setLoading(false);
//             setLoadingMore(false);
//         }
//     };

//     const loadMoreOrders = () => {
//         if (!loadingMore && !noMoreOrders) {
//             fetchInTransitOrders(false);
//         }
//     };

//     // For search functionality across all orders
//     const [allOrders, setAllOrders] = useState([]);
//     const [searchLoading, setSearchLoading] = useState(false);

//     const fetchAllOrdersForSearch = async () => {
//         if (searchTerm.length >= 3) {
//             setSearchLoading(true);
//             try {
//                 const ordersCollection = collection(db, 'users', user.uid, 'orders');
//                 // Get all orders and filter on client-side for search
//                 const q = query(ordersCollection);
                
//                 const ordersSnapshot = await getDocs(q);
//                 const allOrdersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                
//                 // Filter for our tracking statuses
//                 const statusFilteredOrders = allOrdersList.filter(order => 
//                     statusValues.includes(order.tracking_status)
//                 );
//                 setAllOrders(statusFilteredOrders);
                
//                 // Filter based on search term
//                 const filtered = statusFilteredOrders.filter(order => 
//                     order.id.toLowerCase().includes(searchTerm.toLowerCase()) || 
//                     (order.Storeorder_id && order.Storeorder_id.toLowerCase().includes(searchTerm.toLowerCase()))
//                 );
//                 setFilteredOrders(filtered);
//             } catch (error) {
//                 console.error("Error fetching all orders for search:", error);
//                 setError(`Error searching orders: ${error.message}`);
//             } finally {
//                 setSearchLoading(false);
//             }
//         } else if (searchTerm.length === 0) {
//             // Reset to paginated view
//             setFilteredOrders(inTransitOrders);
//         }
//     };

//     useEffect(() => {
//         fetchInTransitOrders(true);
//     }, [shopId]);

//     useEffect(() => {
//         if (searchTerm.length >= 3) {
//             fetchAllOrdersForSearch();
//         } else if (searchTerm.length === 0) {
//             setFilteredOrders(inTransitOrders);
//         } else {
//             // For 1-2 characters, just filter the current paginated results
//             const filtered = inTransitOrders.filter(order => 
//                 order.id.toLowerCase().includes(searchTerm.toLowerCase()) || 
//                 (order.Storeorder_id && order.Storeorder_id.toLowerCase().includes(searchTerm.toLowerCase()))
//             );
//             setFilteredOrders(filtered);
//         }
//     }, [searchTerm]);

//     useEffect(() => {
//         if (searchTerm.length === 0) {
//             setFilteredOrders(inTransitOrders);
//         }
//     }, [inTransitOrders]);

//     const handleSearch = (e) => {
//         setSearchTerm(e.target.value);
//     };

//     if (loading) {
//         return <div className="loading-container">Loading InTransit orders...</div>;
//     }

//     return (
//         <div className="orders-page">
//             <Header />
//             <div className="orders-container">
//                 <Sidebar />
//                 <div className="main-content">
//                     <h1>In Transit</h1>
//                     <div className="search-container">
//                         <input 
//                             type="text" 
//                             placeholder="Search by Order ID or Store Order ID" 
//                             value={searchTerm} 
//                             onChange={handleSearch} 
//                             className="search-bar"
//                         />
//                         {searchLoading && <span className="search-loader">Searching...</span>}
//                     </div>
                    
//                     {error && (
//                         <div className="error-message">
//                             {error}
//                         </div>
//                     )}
                    
//                     {filteredOrders.length > 0 ? (
//                         <table className="orders-table">
//                             <thead>
//                                 <tr>
//                                     <th>Order ID</th>
//                                     <th>Unitee Order ID</th>
//                                     <th>SKU</th>
//                                     <th>Quantity</th>
//                                     <th>Current Status</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {filteredOrders.map(order => (
//                                     <tr key={order.id}>
//                                         <td>{order.Storeorder_id || 'NA'}</td>
//                                         <td>{order.id || 'NA'}</td>
//                                         <td>
//                                             {order.order_items && order.order_items.map ? (
//                                                 order.order_items.map((item, index) => (
//                                                     <p key={index}>{item.sku}</p>
//                                                 ))
//                                             ) : (
//                                                 <p>No items found</p>
//                                             )}
//                                         </td>
//                                         <td>
//                                             {order.order_items && order.order_items.map ? (
//                                                 order.order_items.map((item, index) => (
//                                                     <p key={index}>{item.units}</p>
//                                                 ))
//                                             ) : (
//                                                 <p>No items found</p>
//                                             )}
//                                         </td>
//                                         <td>{order.tracking_status || 'NA'}</td>
//                                     </tr>
//                                 ))}
//                             </tbody>
//                         </table>
//                     ) : (
//                         <div className="no-orders">
//                             {error ? null : "No orders found with the specified tracking statuses"}
//                         </div>
//                     )}
                    
//                     {searchTerm.length === 0 && !noMoreOrders && inTransitOrders.length > 0 && (
//                         <div className="load-more-container">
//                             <button 
//                                 onClick={loadMoreOrders} 
//                                 disabled={loadingMore}
//                                 className="load-more-button"
//                             >
//                                 {loadingMore ? 'Loading...' : 'Load More'}
//                             </button>
//                         </div>
//                     )}
                    
//                     {searchTerm.length === 0 && noMoreOrders && inTransitOrders.length > 0 && (
//                         <div className="no-more-orders">No more orders to load</div>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default InTransit;



import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, getDocs, query, where, limit, startAfter, orderBy } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import '../style/OrdersPage.css';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const InTransit = () => {
    const [inTransitOrders, setInTransitOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const [noMoreOrders, setNoMoreOrders] = useState(false);
    const [error, setError] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shopId = queryParams.get('shop');
    const ordersPerPage = 10;
    
    // Date filter states
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    
    // Payment method filter state
    const [paymentMethodFilter, setPaymentMethodFilter] = useState('all');

    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not logged in");
    }

    // These are the statuses we want to display
    const statusValues = [
        'PICKED UP',
        'Pickup Exception',
        'REACHED AT DESTINATION HUB',
        'SHIPPED',
        'IN TRANSIT',
        'In Transit-AT DESTINATION HUB',
        'In Transit-EN-ROUTE',
        'OUT FOR DELIVERY',
        'DELIVERY DELAYED'
    ];

    const fetchInTransitOrders = async (isInitialLoad = true) => {
        try {
            if (isInitialLoad) {
                setLoading(true);
                setInTransitOrders([]);
                setError(null);
            } else {
                setLoadingMore(true);
            }

            const ordersCollection = collection(db, 'users', user.uid, 'orders');
            
            // DEBUGGING: First try to get all orders to verify data exists
            const debugQuery = query(ordersCollection);
            const debugSnapshot = await getDocs(debugQuery);
            console.log(`DEBUG: Found ${debugSnapshot.size} total orders`);
            
            // Log how many orders have tracking_status in our array
            const validOrders = debugSnapshot.docs.filter(doc => 
                statusValues.includes(doc.data().tracking_status)
            );
            console.log(`DEBUG: Found ${validOrders.length} orders with matching tracking_status`);
            
            // Try without the timestamp ordering first to see if that's the issue
            let q = query(
                ordersCollection,
                where('tracking_status', 'in', statusValues),
                limit(ordersPerPage)
            );

            // If we're loading more, use the lastVisible document as the starting point
            if (!isInitialLoad && lastVisible) {
                q = query(
                    ordersCollection,
                    where('tracking_status', 'in', statusValues),
                    startAfter(lastVisible),
                    limit(ordersPerPage)
                );
            }

            const ordersSnapshot = await getDocs(q);
            console.log(`DEBUG: Query returned ${ordersSnapshot.size} orders`);
            
            // If no documents returned and not initial load, we've reached the end
            if (ordersSnapshot.empty && !isInitialLoad) {
                setNoMoreOrders(true);
                setLoadingMore(false);
                return;
            }

            if (ordersSnapshot.empty && isInitialLoad) {
                console.log("No orders found with the specified tracking statuses");
                setError("No orders found with the specified tracking statuses");
                setInTransitOrders([]);
                return;
            }

            const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            console.log("Fetched orders:", ordersList);
            
            // Update lastVisible for pagination
            const lastDoc = ordersSnapshot.docs[ordersSnapshot.docs.length - 1];
            setLastVisible(lastDoc);

            // Append or set orders based on initial load or loading more
            setInTransitOrders(prevOrders => (
                isInitialLoad ? ordersList : [...prevOrders, ...ordersList]
            ));

        } catch (error) {
            console.error("Error fetching in-transit orders:", error);
            setError(`Error fetching orders: ${error.message}`);
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    const loadMoreOrders = () => {
        if (!loadingMore && !noMoreOrders) {
            fetchInTransitOrders(false);
        }
    };

    // For search functionality across all orders
    const [allOrders, setAllOrders] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);

    const fetchAllOrdersForSearch = async () => {
        if (searchTerm.length >= 3) {
            setSearchLoading(true);
            try {
                const ordersCollection = collection(db, 'users', user.uid, 'orders');
                // Get all orders and filter on client-side for search
                const q = query(ordersCollection);
                
                const ordersSnapshot = await getDocs(q);
                const allOrdersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                
                // Filter for our tracking statuses
                const statusFilteredOrders = allOrdersList.filter(order => 
                    statusValues.includes(order.tracking_status)
                );
                setAllOrders(statusFilteredOrders);
                
                // Apply all filters: search, date range, and payment method
                applyAllFilters(statusFilteredOrders);
            } catch (error) {
                console.error("Error fetching all orders for search:", error);
                setError(`Error searching orders: ${error.message}`);
            } finally {
                setSearchLoading(false);
            }
        } else if (searchTerm.length === 0) {
            // Apply only date and payment filters to paginated orders
            applyAllFilters(inTransitOrders);
        }
    };

    const applyAllFilters = (orders) => {
        let filtered = orders;
        
        // Apply search filter
        if (searchTerm.length > 0) {
            filtered = filtered.filter(order => 
                order.id.toLowerCase().includes(searchTerm.toLowerCase()) || 
                (order.Storeorder_id && order.Storeorder_id.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        }
        
        // Apply date filters if set
        if (fromDate) {
            const fromDateTime = new Date(fromDate).getTime();
            filtered = filtered.filter(order => {
                if (!order.order_date) return false;
                const orderDate = new Date(order.order_date).getTime();
                return orderDate >= fromDateTime;
            });
        }
        
        if (toDate) {
            const toDateTime = new Date(toDate).setHours(23, 59, 59, 999);
            filtered = filtered.filter(order => {
                if (!order.order_date) return false;
                const orderDate = new Date(order.order_date).getTime();
                return orderDate <= toDateTime;
            });
        }
        
        // Apply payment method filter
        if (paymentMethodFilter !== 'all') {
            filtered = filtered.filter(order => 
                order.payment_method === paymentMethodFilter
            );
        }
        
        setFilteredOrders(filtered);
    };

    useEffect(() => {
        fetchInTransitOrders(true);
    }, [shopId]);

    useEffect(() => {
        if (searchTerm.length >= 3 || fromDate || toDate || paymentMethodFilter !== 'all') {
            fetchAllOrdersForSearch();
        } else if (searchTerm.length === 0 && !fromDate && !toDate && paymentMethodFilter === 'all') {
            setFilteredOrders(inTransitOrders);
        } else {
            // For 1-2 characters of search term, just filter the current paginated results
            applyAllFilters(inTransitOrders);
        }
    }, [searchTerm, fromDate, toDate, paymentMethodFilter]);

    useEffect(() => {
        if (searchTerm.length === 0 && !fromDate && !toDate && paymentMethodFilter === 'all') {
            setFilteredOrders(inTransitOrders);
        } else {
            applyAllFilters(inTransitOrders);
        }
    }, [inTransitOrders]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handlePaymentMethodChange = (e) => {
        setPaymentMethodFilter(e.target.value);
    };

    if (loading) {
        return <div className="loading-container">Loading InTransit orders...</div>;
    }

    return (
        <div className="orders-page">
            <Header />
            <div className="orders-container">
                <Sidebar />
                <div className="main-content">
                    <h1>In Transit</h1>
                    <div className="filters-container">
                        <div className="search-container">
                            <input 
                                type="text" 
                                placeholder="Search by Order ID or Store Order ID" 
                                value={searchTerm} 
                                onChange={handleSearch} 
                                className="search-bar"
                            />
                            {searchLoading && <span className="search-loader">Searching...</span>}
                        </div>
                        
                        <div className="date-filters">
                            <div className="date-filter">
                                <label>From Date:</label>
                                <input 
                                    type="date" 
                                    value={fromDate} 
                                    onChange={handleFromDateChange}
                                />
                            </div>
                            <div className="date-filter">
                                <label>To Date:</label>
                                <input 
                                    type="date" 
                                    value={toDate} 
                                    onChange={handleToDateChange}
                                />
                            </div>
                        </div>
                        
                        <div className="payment-filter">
                            <label>Payment Method:</label>
                            <select 
                                value={paymentMethodFilter} 
                                onChange={handlePaymentMethodChange}
                            >
                                <option value="all">All</option>
                                <option value="COD">COD</option>
                                <option value="Prepaid">Prepaid</option>
                            </select>
                        </div>
                    </div>
                    
                    {error && (
                        <div className="error-message">
                            {error}
                        </div>
                    )}
                    
                    {filteredOrders.length > 0 ? (
                        <table className="orders-table">
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Unitee Order ID</th>
                                    <th>SKU</th>
                                    <th>Quantity</th>
                                    <th>Order Date</th>
                                    <th>Payment Method</th>
                                    <th>Total Order Value</th>
                                    <th>Current Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredOrders.map(order => (
                                    <tr key={order.id}>
                                        <td>{order.Storeorder_id || 'NA'}</td>
                                        <td>{order.id || 'NA'}</td>
                                        <td>
                                            {order.order_items && order.order_items.map ? (
                                                order.order_items.map((item, index) => (
                                                    <p key={index}>{item.sku}</p>
                                                ))
                                            ) : (
                                                <p>No items found</p>
                                            )}
                                        </td>
                                        <td>
                                            {order.order_items && order.order_items.map ? (
                                                order.order_items.map((item, index) => (
                                                    <p key={index}>{item.units}</p>
                                                ))
                                            ) : (
                                                <p>No items found</p>
                                            )}
                                        </td>
                                        <td>{order.order_date ? new Date(order.order_date).toLocaleDateString() : 'NA'}</td>
                                        <td>{order.payment_method || 'NA'}</td>
                                        <td>{order.total || 'NA'}</td>
                                        <td>{order.tracking_status || 'NA'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="no-orders">
                            {error ? null : "No orders found with the specified criteria"}
                        </div>
                    )}
                    
                    {searchTerm.length === 0 && !fromDate && !toDate && paymentMethodFilter === 'all' && !noMoreOrders && inTransitOrders.length > 0 && (
                        <div className="load-more-container">
                            <button 
                                onClick={loadMoreOrders} 
                                disabled={loadingMore}
                                className="load-more-button"
                            >
                                {loadingMore ? 'Loading...' : 'Load More'}
                            </button>
                        </div>
                    )}
                    
                    {searchTerm.length === 0 && !fromDate && !toDate && paymentMethodFilter === 'all' && noMoreOrders && inTransitOrders.length > 0 && (
                        <div className="no-more-orders">No more orders to load</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InTransit;