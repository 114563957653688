import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { collection, query, where, getDocs, limit, startAfter } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import '../style/OrdersPage.css';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const Delivered = () => {
    const [deliveredOrders, setDeliveredOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState('');
    const [loadingMore, setLoadingMore] = useState(false);
    const [noMoreOrders, setNoMoreOrders] = useState(false);
    const [error, setError] = useState(null);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const queryParams = new URLSearchParams(location.search);
    const shopId = queryParams.get('shop');
    const [searchLoading, setSearchLoading] = useState(false);
    const [lastVisible, setLastVisible] = useState(null);
    const ordersPerPage = 10;

    // Date filter states
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    
    // Payment method filter state
    const [paymentMethodFilter, setPaymentMethodFilter] = useState('all');

    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not logged in");
    }

    const fetchDeliveredOrders = async (isInitialLoad = true) => {
        try {
            if (isInitialLoad) {
                setLoading(true);
                setDeliveredOrders([]);
                setError(null);
            } else {
                setLoadingMore(true);
            }

            const ordersCollection = collection(db, 'users', user.uid, 'orders');
            
            // Create a query for orders with status "Delivered"
            let deliveredQuery;
            
            if (!isInitialLoad && lastVisible) {
                deliveredQuery = query(
                    ordersCollection, 
                    where("tracking_status", "==", "DELIVERED"),
                    startAfter(lastVisible),
                    limit(ordersPerPage)
                );
            } else {
                deliveredQuery = query(
                    ordersCollection, 
                    where("tracking_status", "==", "DELIVERED"),
                    limit(ordersPerPage)
                );
            }
            
            const ordersSnapshot = await getDocs(deliveredQuery);
            
            // If no documents returned and not initial load, we've reached the end
            if (ordersSnapshot.empty && !isInitialLoad) {
                setNoMoreOrders(true);
                setLoadingMore(false);
                return;
            }

            if (ordersSnapshot.empty && isInitialLoad) {
                setError("No orders found with the specified tracking statuses");
                setDeliveredOrders([]);
                return;
            }
            
            // Update lastVisible for pagination
            const lastDoc = ordersSnapshot.docs[ordersSnapshot.docs.length - 1];
            setLastVisible(lastDoc);
            
            const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            
            // Append or set orders based on initial load or loading more
            setDeliveredOrders(prevOrders => (
                isInitialLoad ? ordersList : [...prevOrders, ...ordersList]
            ));
        } catch (error) {
            console.error("Error fetching delivered orders:", error);
            setError(`Error fetching orders: ${error.message}`);
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    // For search functionality across all orders
    const [allOrders, setAllOrders] = useState([]);

    const fetchAllOrdersForSearch = async () => {
        if (searchTerm.length >= 3 || fromDate || toDate || paymentMethodFilter !== 'all') {
            setSearchLoading(true);
            try {
                const ordersCollection = collection(db, 'users', user.uid, 'orders');
                // Get all delivered orders and filter on client-side
                const q = query(ordersCollection, where("tracking_status", "==", "DELIVERED"));
                
                const ordersSnapshot = await getDocs(q);
                const allOrdersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setAllOrders(allOrdersList);
                
                // Apply all filters
                applyAllFilters(allOrdersList);
            } catch (error) {
                console.error("Error fetching all orders for search:", error);
                setError(`Error searching orders: ${error.message}`);
            } finally {
                setSearchLoading(false);
            }
        } else if (searchTerm.length === 0 && !fromDate && !toDate && paymentMethodFilter === 'all') {
            // Reset to paginated view
            setFilteredOrders(deliveredOrders);
        }
    };

    const applyAllFilters = (orders) => {
        let filtered = orders;
        
        // Apply search filter
        if (searchTerm.length > 0) {
            filtered = filtered.filter(order => 
                order.id.toLowerCase().includes(searchTerm.toLowerCase()) || 
                (order.Storeorder_id && order.Storeorder_id.toLowerCase().includes(searchTerm.toLowerCase()))
            );
        }
        
        // Apply date filters if set
        if (fromDate) {
            const fromDateTime = new Date(fromDate).getTime();
            filtered = filtered.filter(order => {
                if (!order.order_date) return false;
                const orderDate = new Date(order.order_date).getTime();
                return orderDate >= fromDateTime;
            });
        }
        
        if (toDate) {
            const toDateTime = new Date(toDate).setHours(23, 59, 59, 999);
            filtered = filtered.filter(order => {
                if (!order.order_date) return false;
                const orderDate = new Date(order.order_date).getTime();
                return orderDate <= toDateTime;
            });
        }
        
        // Apply payment method filter
        if (paymentMethodFilter !== 'all') {
            filtered = filtered.filter(order => 
                order.payment_method === paymentMethodFilter
            );
        }
        
        setFilteredOrders(filtered);
    };

    useEffect(() => {
        fetchDeliveredOrders(true);
    }, [shopId]);

    useEffect(() => {
        if (searchTerm.length >= 3 || fromDate || toDate || paymentMethodFilter !== 'all') {
            fetchAllOrdersForSearch();
        } else if (searchTerm.length === 0 && !fromDate && !toDate && paymentMethodFilter === 'all') {
            setFilteredOrders(deliveredOrders);
        } else {
            // For 1-2 characters of search term, just filter the current paginated results
            applyAllFilters(deliveredOrders);
        }
    }, [searchTerm, fromDate, toDate, paymentMethodFilter]);

    useEffect(() => {
        if (searchTerm.length === 0 && !fromDate && !toDate && paymentMethodFilter === 'all') {
            setFilteredOrders(deliveredOrders);
        } else {
            applyAllFilters(deliveredOrders);
        }
    }, [deliveredOrders]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handlePaymentMethodChange = (e) => {
        setPaymentMethodFilter(e.target.value);
    };

    const loadMoreOrders = () => {
        if (!loadingMore && !noMoreOrders) {
            fetchDeliveredOrders(false);
        }
    };

    if (loading) {
        return <div className="loading-container">Loading Delivered Orders...</div>;
    }

    return (
        <div className="orders-page">
            <Header />
            <div className="orders-container">
                <Sidebar />
                <div className="main-content">
                    <h1>Delivered Orders</h1>
                    <div className="filters-container">
                        <div className="search-container">
                            <input 
                                type="text" 
                                placeholder="Search by Order ID or Store Order ID" 
                                value={searchTerm} 
                                onChange={handleSearch} 
                                className="search-bar"
                            />
                            {searchLoading && <span className="search-loader">Searching...</span>}
                        </div>
                        
                        <div className="date-filters">
                            <div className="date-filter">
                                <label>From Date:</label>
                                <input 
                                    type="date" 
                                    value={fromDate} 
                                    onChange={handleFromDateChange}
                                />
                            </div>
                            <div className="date-filter">
                                <label>To Date:</label>
                                <input 
                                    type="date" 
                                    value={toDate} 
                                    onChange={handleToDateChange}
                                />
                            </div>
                        </div>
                        
                        <div className="payment-filter">
                            <label>Payment Method:</label>
                            <select 
                                value={paymentMethodFilter} 
                                onChange={handlePaymentMethodChange}
                            >
                                <option value="all">All</option>
                                <option value="COD">COD</option>
                                <option value="Prepaid">Prepaid</option>
                            </select>
                        </div>
                    </div>
                    
                    {error && (
                        <div className="error-message">
                            {error}
                        </div>
                    )}
                    
                    {filteredOrders.length > 0 ? (
                        <table className="orders-table">
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Unitee Order ID</th>
                                    <th>SKU</th>
                                    <th>Quantity</th>
                                    <th>Order Date</th>
                                    <th>Payment Method</th>
                                    <th>Total Order Value</th>
                                    <th>Current Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredOrders.map(order => (
                                    <tr key={order.id}>
                                        <td>{order.Storeorder_id || 'NA'}</td>
                                        <td>{order.id || 'NA'}</td>
                                        <td>
                                            {order.order_items && order.order_items.map ? (
                                                order.order_items.map((item, index) => (
                                                    <p key={index}>{item.sku}</p>
                                                ))
                                            ) : (
                                                <p>No items found</p>
                                            )}
                                        </td>
                                        <td>
                                            {order.order_items && order.order_items.map ? (
                                                order.order_items.map((item, index) => (
                                                    <p key={index}>{item.units}</p>
                                                ))
                                            ) : (
                                                <p>No items found</p>
                                            )}
                                        </td>
                                        <td>{order.order_date ? new Date(order.order_date).toLocaleDateString() : 'NA'}</td>
                                        <td>{order.payment_method || 'NA'}</td>
                                        <td>{order.total || 'NA'}</td>
                                        <td>{order.tracking_status || 'NA'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="no-orders">
                            {error ? null : "No orders found with the specified criteria"}
                        </div>
                    )}
                    
                    {searchTerm.length === 0 && !fromDate && !toDate && paymentMethodFilter === 'all' && !noMoreOrders && deliveredOrders.length > 0 && (
                        <div className="load-more-container">
                            <button 
                                onClick={loadMoreOrders} 
                                disabled={loadingMore}
                                className="load-more-button"
                            >
                                {loadingMore ? 'Loading...' : 'Load More'}
                            </button>
                        </div>
                    )}
                    
                    {searchTerm.length === 0 && !fromDate && !toDate && paymentMethodFilter === 'all' && noMoreOrders && deliveredOrders.length > 0 && (
                        <div className="no-more-orders">No more orders to load</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Delivered;